import React, { useState, useEffect } from 'react';
import './profile.css';
import { getUserData } from './api';


const Profile = ({ handleLogout}) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUserData();
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, []);

 
  const logout = () => {
    console.log('Removing token from local storage');
    localStorage.removeItem('authToken');
    handleLogout();
    window.location.reload();
  };

  return (
    <div className="profile">
      <h2 className='profileheader'>User Profile</h2>
      <p>Name: {user.username}</p>
      <p>Email: {user.email}</p>
      {/* Add more user information if needed */}
      <button className="logout-btn" onClick={logout}>
        Logout
      </button>
    </div>
  );
};

export default Profile;
