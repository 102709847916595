import React, { useState, useEffect } from 'react';
import { editWorkOrder } from './api';
import './workorder.css'
import WorkOrderHistory from './workorderhistory';

const AUTHORIZED_TOKEN = "22304b8a4adbe6206dd82480efa99eb532471521";

const WorkOrder = ({ workOrder, onClose }) => {
  const [editedWorkOrder, setEditedWorkOrder] = useState({ ...workOrder });
  const [showHistory, setShowHistory] = useState(false); // State to control the history component
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("authToken"); // Adjust "authToken" based on how you store it
    setIsAuthorized(token === AUTHORIZED_TOKEN);
  }, []);
  

  const handleChange = (e) => {
    setEditedWorkOrder({ ...editedWorkOrder, [e.target.name]: e.target.value });
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Call the API to save changes
      await editWorkOrder(workOrder.id, editedWorkOrder);
      onClose();
      window.location.reload();
    } catch (error) {
      console.error('Error updating work order:', error);
      alert('Failed to update work order');
    }
  };

  const handleHistoryClick = () => {
    setShowHistory(true); // Show the history component when the "history" button is clicked
  };

  

  return (
    <form className='workorder' onSubmit={handleSubmit}>
      <h2 className='workorderheader'>Work Order #{workOrder.id} </h2>
      <div className='workorderform'>
      
      
      
    <div className="workordertop">
    <div className='workordercontact'>
      <div className='contactcolumn'>
      {/* Name */}
      <label className='labelcontact'>
        Name:
        <input
          className='inputscontact'
          type="text"
          name="name"
          value={workOrder.customer_name}
          onChange={handleChange}
        />
      </label>

      {/* Phone */}
      <label className='labelcontact'>
        Phone:
        <input
        className='inputscontact'
        type="tel"
        name="phone"
        value={editedWorkOrder.phone}
        onChange={handleChange}
        pattern="[0-9]{10}"
        placeholder="1234567890"
        />
      </label>
      </div>
      {/* Vehicle */}
      <label className='labelcontact'>
        Vehicle:
        <input
          className='inputscontact'
          type="text"
          name="vehicle"
          value={editedWorkOrder.vehicle}
          onChange={handleChange}
        />
      </label>
      </div>
      

     <div className='workorderimportantinfo'>
      {/* Price */}
      <label className='labelimportantinfo'>
        Price:
        <input
          className='inputsprice'
          type="number"
          step="0.01"
          name="price"
          value={editedWorkOrder.price}
          onChange={handleChange}
        />
      </label>
      <div>
      <label className='labelimportantinfo'>
        Date Created:
        <input
          className='inputsprice'
          type="text"
          name="created_at"
          value={editedWorkOrder.created_at}
          onChange={handleChange}
        />
      </label>

      {/* Date Promised */}
      <label className='labelimportantinfo'>
        Date Promised:
        <input
          className='inputsprice'
          type="date"
          name="date_promised"
          value={editedWorkOrder.date_promised}
          onChange={handleChange}
        />
      </label>
      </div>
      <label className='labelimportantinfo'>
  Status:
  <select
    className='inputsprice'
    name="status"
    value={editedWorkOrder.status}
    onChange={(e) => setEditedWorkOrder({ ...editedWorkOrder, status: e.target.value })}
  >
    <option value="In Process">In Process</option>
    <option value="Called">Called</option>
    <option value="Payed">Paid</option>
  </select>
</label>
      

<div className='labelimportantinfo'>
  Archived:
  {isAuthorized ? (
    <input
      className='inputsprice'
      type="checkbox"
      name="is_archived"
      checked={editedWorkOrder.is_archived}
      onChange={(e) => setEditedWorkOrder({ ...editedWorkOrder, is_archived: e.target.checked })}
    />
  ) : (
    <input
      className='inputsprice'
      type="checkbox"
      name="is_archived"
      checked={editedWorkOrder.is_archived}
      disabled={true} // Disable the checkbox if the user is not authorized
    />
  )}
</div>
      </div>
      </div>
      <div className='workorderdetails'>
      <div className="workorderdetailssmall">
      {/* Service Requested */}
      <label className='labeldetails'>
  Service Requested:
  <select
    className='inputsdetails'
    name="service_requested"
    value={editedWorkOrder.service_requested}
    onChange={(e) => setEditedWorkOrder({ ...editedWorkOrder, service_requested: e.target.value })}
    defaultValue='Restoration'
  >
    <option value="Restoration">Restoration</option>
    <option value="Repair Crack">Repair Crack</option>
    <option value="Powder Coating">Powder Coating</option>
    <option value="Straightening">Straightening</option>
    <option value="Mount & Balance">Mount & Balance</option>
  </select>
</label>


      {/* Number of Wheels/Parts */}
      <label className='labeldetails'>
        Number of Wheels/Parts:
        <input
          className='inputsdetails'
          type="number"
          name="number_of_wheels"
          value={editedWorkOrder.number_of_wheels}
          onChange={handleChange}
        />
      </label>

      {/* Tires */}
      <label className='labeldetails'>
  Tires:
  <select
    className='inputsdetails'
    name="tires"
    value={editedWorkOrder.tires}
    onChange={(e) => setEditedWorkOrder({ ...editedWorkOrder, tires: e.target.value })}
  >
    <option value="Yes">Yes</option>
    <option value="No">No</option>
    <option value="New">New</option>
  </select>
</label>

      {/* Center Caps */}
      <label className='labeldetails'>
  Center Caps:
  <select
    className='inputsdetails'
    name="center_caps"
    value={editedWorkOrder.center_caps}
    onChange={(e) => setEditedWorkOrder({ ...editedWorkOrder, center_caps: e.target.value })}
  >
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>
</label>


      {/* Straightening */}
      <label className='labeldetails'>
  Straightening:
  <select
    className='inputsdetails'
    name="straightening"
    value={editedWorkOrder.straightening}
    onChange={(e) => setEditedWorkOrder({ ...editedWorkOrder, straightening: e.target.value })}
  >
    <option value='None'>None</option>
    <option value="LF">LF</option>
    <option value="LR">LR</option>
    <option value="RF">RF</option>
    <option value="RR">RR</option>
  </select>
</label>
</div>

<div className='workorderdetailslarge'>
      {/* Description */}
      <label className='labeldetailslarge'>
        Description:
        <textarea
          className='inputsdetailslarge'
          name="description"
          value={editedWorkOrder.description}
          onChange={handleChange}
        />
      </label>

      {/* Notes */}
      <label className='labeldetailslarge'>
        Notes:
        <textarea
          className='inputsdetailslarge'
          name="notes"
          value={editedWorkOrder.notes}
          onChange={handleChange}
        />
      </label>
      </div>
      </div>
      </div>
      <div className='savebuttonbanner'>
      <button type='button' className='historybutton' onClick={handleHistoryClick}>history</button>
      <button  className='savebutton' type="submit">Save</button>
      </div>
      {showHistory && (
        <WorkOrderHistory
          workOrderId={workOrder.id}
          onClose={() => setShowHistory(false)}
          
        />
      )}
    </form>
  );
};

export default WorkOrder;