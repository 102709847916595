import React from 'react';
import './loginsignupmodal.css';
import LoginForm from './loginform.js';
import Profile from './profile.js';
import { useUserContext } from './UserContext'; // Import the useUserContext hook

const LoginSignupModal = ({ showModal, toggleModal }) => {
  const { user, setUser} = useUserContext(); // Get the user and setUser from the context

  const handleLogout = () => {
    // Perform your logout logic here (e.g., clearing tokens, etc.)
    setUser(null);
    
    toggleModal();
  };

  if (!showModal) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <button className="modal-close-btn" onClick={toggleModal}>
          &times;
        </button>
        {user ? (
          <Profile user={user} handleLogout={handleLogout} />
        ) : (
          <div className="login-form">
            <LoginForm closeModal={toggleModal} />
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginSignupModal;
