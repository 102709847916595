import React, { useState, useEffect } from 'react';
import { fetchWorkOrderHistory } from './api'; // Adjust the import path as necessary
import './workorderhistory.css'

const WorkOrderHistory = ({ workOrderId, onClose }) => {
    const [changes, setChanges] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const loadHistoryAndDetectChanges = async () => {
            setLoading(true);
            try {
                const data = await fetchWorkOrderHistory(workOrderId);
                const changes = detectChanges(data);
                setChanges(changes);
                setError('');
            } catch (error) {
                console.error('Failed to fetch work order history:', error);
                setError('Failed to load history');
            } finally {
                setLoading(false);
            }
        };

        loadHistoryAndDetectChanges();
    }, [workOrderId]);

    // Function to detect changes between history records
    const detectChanges = (history) => {
        return history.reduce((acc, current, index, array) => {
            if (index === 0) return acc; // Skip the first item as there's nothing to compare with

            const previous = array[index - 1];
            const change = {};

            // Loop through the fields of the current record
            Object.keys(current).forEach(key => {
                if (current[key] !== previous[key] && key !== 'history_date' && key !== 'id' && key !== 'history_id') {
                    // If a change is detected, add it to the change object
                    change[key] = { from: previous[key], to: current[key] };
                }
            });

            if (Object.keys(change).length) {
                // Add the change object to the accumulator if any changes were found
                acc.push({ date: current.history_date, changes: change });
            }

            return acc;
        }, []);
    };

    return (
        <div className="workorderhistory">
            <button className='modal-close-btn'onClick={onClose}>Close History</button>
            <h2>History</h2>
            {loading && <p>Loading history...</p>}
            {error && <p>{error}</p>}
            <ul>
                {changes.map((entry, index) => (
                    <li key={index}>
                        <p>Date: {new Date(entry.date).toLocaleDateString()}</p>
                        {Object.entries(entry.changes).map(([field, { from, to }]) => (
                            <div key={field}>
                                <strong>{field}:</strong> from "{from}" to "{to}"
                            </div>
                        ))}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default WorkOrderHistory;
