import React from 'react';
import './newworkordermodal.css';
import NewWorkOrder from './newworkorder';

const NewWorkOrderModal = ({ showModal, toggleModal, onWorkOrderCreated }) => {
  if (!showModal) {
    return null;
  }

  return (
    <div className="newworkordermodal-overlay">
      <div className="newworkordermodal-content">
        <button className="modal-close-btn" onClick={toggleModal}>
          &times;
        </button>
        <NewWorkOrder onWorkOrderCreated={onWorkOrderCreated} />
      </div>
    </div>
  );
};

export default NewWorkOrderModal;
