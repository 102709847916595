import React, { useState, useEffect } from 'react';
import { fetchUsers } from './api'; // Assuming this API call exists
import './customers.css';
import CustomerWorkOrderList from './customerworkorderlist';

const Customers = () => {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showWorkOrders, setShowWorkOrders] = useState(false);

  useEffect(() => {
    const loadUsers = async () => {
      const fetchedUsers = await fetchUsers(searchQuery);
      if (fetchedUsers) {
        setUsers(fetchedUsers);
      }
    };

    loadUsers();
  }, [searchQuery]);

  const handleCustomerClick = (customer) => {
    setSelectedCustomer(customer);
    console.log(customer)
    setShowWorkOrders(true);
  };

  const handleBack = () => {
    setShowWorkOrders(false);
    setSelectedCustomer(null);
  };

  return (
    <div className='customerlist'>
      {showWorkOrders && selectedCustomer ? (
        <div>
          <button onClick={handleBack}>Back to Customer List</button>
          <CustomerWorkOrderList customerName={selectedCustomer.name} />
        </div>
      ) : (
        <div className="customerlistdiv">
          <label className='searchlabel'>Search:
            <input
              className='searchbar'
              type="text"
              placeholder="⌕ Search Customers"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </label>

          <div className='customerlistlabels'>
            <h5 className='customerlistlabelsheaders'>Name</h5>
            <h5 className='customerlistlabelsheaders'>Phone Number</h5>
            <h5 className='customerlistlabelsheaders'>Email</h5>
          </div>
          <hr />
          <div className='customerlistrowscroll'>

          {users && users.length > 0 ? (
            users.map((customer) => (
              <div className='customerlistrow' key={customer.id} onClick={() => handleCustomerClick(customer)}>
                <div className='customerlistrowdata'>{customer.name}</div>
                <div className='customerlistrowdata'>{customer.phone}</div>
                <div className='customerlistrowdata'>{customer.email}</div>
              </div>
            ))
          ) : (
            <p>No customers found</p>
          )}
        </div>
        </div>
      )}
    </div>
  );
};

export default Customers;


