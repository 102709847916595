import React, { useState } from 'react';
import { createWorkOrder, createCustomer } from './api'; // Import createCustomer API call

const NewWorkOrder = ({ onWorkOrderCreated }) => {
  const [newWorkOrder, setNewWorkOrder] = useState({
    customerName: '',
    phone: '',
    vehicle: '',
    
    number_of_wheels: '',
    
    
    
    description: '',
    notes: '',
    price: '',
    date_promised: '',
    is_archived: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewWorkOrder({
      ...newWorkOrder,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Step 1: Create Customer
      const customerData = { name: newWorkOrder.customerName };
      const customer = await createCustomer(customerData);
      console.log("Created customer:", customer);

      if (customer) {
        // Step 2: Create WorkOrder with linked customer
        const workOrderData = {
          ...newWorkOrder,
          customer: customer.id, // Assuming the customer object has an ID
        };
        const createdWorkOrder = await createWorkOrder(workOrderData);

        if (createdWorkOrder) {
          onWorkOrderCreated(createdWorkOrder);
          setNewWorkOrder({
            customerName: '',
            phone: '',
            vehicle: '',
            // ... reset other fields ...
            is_archived: false,
          });
        }
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle error appropriately
    }
  };
  return (
    <form onSubmit={handleSubmit} className='workorder'>
  <h2 className='workorderheader'>New Work Order</h2>
  <div className='workorderform'>

    {/* Name */}
    <div className="workordertop">
    <div className='workordercontact'>
      <div className='contactcolumn'>
        <label className='labelcontact'>
          Name:
          <input
            className='inputscontact'
            type="text"
            name="customerName"
            value={newWorkOrder.customerName}
            onChange={handleChange}
          />
        </label>

        {/* Phone */}
        <label className='labelcontact'>
          Phone:
          <input
            className='inputscontact'
            type="tel"
            name="phone"
            value={newWorkOrder.phone}
            onChange={handleChange}
            pattern="[0-9]{10}"
            placeholder="1234567890"
          />
        </label>
      </div>

      {/* Vehicle */}
      <label className='labelcontact'>
        Vehicle:
        <input
          className='inputscontact'
          type="text"
          name="vehicle"
          value={newWorkOrder.vehicle}
          onChange={handleChange}
        />
      </label>
    </div>

    <div className='workorderimportantinfo'>
      {/* Price */}
      <label className='labelimportantinfo'>
        Price:
        <input
          className='inputsprice'
          type="number"
          step="0.01"
          name="price"
          value={newWorkOrder.price}
          onChange={handleChange}
        />
      </label>

      {/* Date Promised */}
      <label className='labelimportantinfo'>
        Date Promised:
        <input
          className='inputsprice'
          type="date"
          name="date_promised"
          value={newWorkOrder.date_promised}
          onChange={handleChange}
        />
      </label>

      {/* Status */}
      <label className='labelimportantinfo'>
        Status:
        <select
          className='inputsprice'
          name="status"
          value={newWorkOrder.status}
          onChange={handleChange}
        >
          <option value="In Process">In Process</option>
          <option value="Called">Called</option>
          <option value="Payed">Paid</option>
        </select>
      </label>
    </div>
    </div>

    <div className='workorderdetails'>
      <div className="workorderdetailssmall">
        {/* Service Requested */}
        <label className='labeldetails'>
          Service Requested:
          <select
            className='inputsdetails'
            name="service_requested"
            value={newWorkOrder.service_requested}
            onChange={handleChange}
          >
            <option value="Restoration">Restoration</option>
            <option value="Repair Crack">Repair Crack</option>
            <option value="Powder Coating">Powder Coating</option>
            <option value="Straightening">Straightening</option>
            <option value="Mount & Balance">Mount & Balance</option>
          </select>
        </label>

        {/* Number of Wheels/Parts */}
        <label className='labeldetails'>
          Number of Wheels/Parts:
          <input
            className='inputsdetails'
            type="number"
            name="number_of_wheels"
            value={newWorkOrder.number_of_wheels}
            onChange={handleChange}
          />
        </label>

        {/* Tires */}
        <label className='labeldetails'>
          Tires:
          <select
            className='inputsdetails'
            name="tires"
            value={newWorkOrder.tires}
            onChange={handleChange}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <option value="New">New</option>
          </select>
        </label>

        {/* Center Caps */}
        <label className='labeldetails'>
          Center Caps:
          <select
            className='inputsdetails'
            name="center_caps"
            value={newWorkOrder.center_caps}
            onChange={handleChange}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        {/* Straightening */}
        <label className='labeldetails'>
          Straightening:
          <select
            className='inputsdetails'
            name="straightening"
            value={newWorkOrder.straightening}
            onChange={handleChange}
          >
            <option value='None'>None</option>
            <option value="LF">LF</option>
            <option value="LR">LR</option>
            <option value="RF">RF</option>
            <option value="RR">RR</option>
          </select>
        </label>
      </div>

      <div className='workorderdetailslarge'>
        {/* Description */}
        <label className='labeldetailslarge'>
          Description:
          <textarea
            className='inputsdetailslarge'
            name="description"
            value={newWorkOrder.description}
            onChange={handleChange}
          />
        </label>

        {/* Notes */}
        <label className='labeldetailslarge'>
          Notes:
          <textarea
            className='inputsdetailslarge'
            name="notes"
            value={newWorkOrder.notes}
            onChange={handleChange}
          />
        </label>
      </div>
    </div>
  </div>

  <div className='savebuttonbanner'>
    <div></div>
    <button className='savebutton' type="submit">Create</button>
  </div>
</form>


  );
};

export default NewWorkOrder;
