import axios from 'axios';

const USER_API_URL = 'https://api.paragonpowder.com/users/';
const API_BASE_URL = 'https://api.paragonpowder.com/WorkOrder/'; 
const CUST_BASE_URL = 'https://api.paragonpowder.com/customers/'; 

const getAuthToken = () => {
    const token = localStorage.getItem('authToken');
    console.log('Token in getAuthToken:', token); 
    return token;
  };
  
  const authHeader = () => {
    const token = getAuthToken();
    console.log('Token in authHeader:', token);
    if (token) {
      console.log('Token found:', token);
      return { Authorization: `Token ${token}` };
    }
    return {};
  };
  
  export const register = async (username, email, password) => {
    const headers = authHeader();
    const response = await axios.post(USER_API_URL + 'register/', {
      username,
      email,
      password,
    }, { headers });
    
    const token = response.data.token;
    localStorage.setItem('authToken', token);
    return response.data;
  };
  
  export const login = async (username, password) => {
    const response = await axios.post(USER_API_URL + 'login/', {
      username,
      password,
    });
    const token = response.data.token;
    localStorage.setItem('authToken', token);
    return response.data;
  };

  export const setAuthToken = (token) => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Token ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  };
  
  export const getUserData = () => {
    const headers = authHeader();
    return axios.get(USER_API_URL + 'user_data/', { headers });
  };



//WorkOrder APIS

export const fetchWorkOrders = async (service, date, search, status) => {
  const response = await axios.get(`${API_BASE_URL}list/`, {
    params: { service, date, search, status },
    headers: authHeader()
  });
  return response.data;
};

export const editWorkOrder = async (workOrderId, workOrderData) => {
  try {
      const response = await axios.put(`${API_BASE_URL}edit/${workOrderId}/`, workOrderData, { headers: authHeader() });
      return response.data;
  } catch (error) {
      console.error('Error editing work order:', error);
  }
}; 






export const createWorkOrder = async (workOrderData) => {
  try {
    const response = await axios.post(API_BASE_URL, workOrderData);
    return response.data;
  } catch (error) {
    console.error('Error creating work order:', error);
    // Handle errors appropriately
  }
};

export const createCustomer = async (customerData) => {
  try {
    const response = await axios.post(`${CUST_BASE_URL}create/`, customerData);
    return response.data;
  } catch (error) {
    console.error('Error creating customer:', error);
    throw error;
  }
};



//export const fetchWorkOrders = async () => {
 // try {
 //   const response = await axios.get(`${API_BASE_URL}list/`);
 //   return response.data;
 // } catch (error) {
 //   console.error('Error fetching work orders:', error);
  //  return null;
  //}
//};


export const fetchUsers = async (search) => {
  const response = await axios.get(CUST_BASE_URL, {
    params: { search },
    headers: authHeader()
  });
  return response.data;
};




export const fetchWorkOrderHistory = async (workOrderId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}history/${workOrderId}/`, {
      headers: authHeader(), // Include authentication headers if needed
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching work order history:', error);
    throw error;
  }
};
