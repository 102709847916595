
import React from 'react';
import "./reports.css"
const Reports = () => {
  
  
  
  return (
    <div>
      
      <h2>Reports</h2>
      

    </div>
  );
};

export default Reports;