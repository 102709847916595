import React from 'react';
import './workordermodal.css'; // Importing the CSS for styling
import WorkOrder from './workorder';

const WorkOrderModal = ({ showModal, toggleModal, workOrder }) => {
  if (!showModal) {
    return null;
  }

  return (
    <div className="workordermodal-overlay" >
      <div className="workordermodal-content" >
      <button className="modal-close-btn" onClick={toggleModal}>
          &times;
        </button>
        {workOrder && <WorkOrder workOrder={workOrder} onClose={toggleModal}  />}

      </div>
    </div>
  );
};

export default WorkOrderModal;
