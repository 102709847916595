import React, { useState } from 'react';
import './loginform.css';
import { login, setAuthToken } from './api.js';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from './UserContext';


const LoginForm = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { setUser} = useUserContext();
  

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform login request to backend API
    login(username, password)
      .then(async (response) => {
        console.log('Login successful:', response);
        localStorage.setItem('authToken', response.token); // Save the token in local storage
        console.log('Token saved in local storage:', localStorage.getItem('authToken'));
        setAuthToken(response.token);
        setUser(response); // Save the user data in the context
        console.log('user set in context');
        window.location.reload();
        // Redirect to main page
        navigate('/');
        // Close the modal
        props.closeModal();
      })
      .catch((error) => {
        console.error('Login error:', error.response.data);
        alert('Login failed. Please try again')
        // Handle login errors (show error message)
      });
  };
  
  
  



  return (
    <form className="login-form" onSubmit={handleSubmit}>
      
      <h3 className="form-title">Log In</h3>
      <div className="form-group">
        <label htmlFor="username">Username</label>
        <input
          type="text"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>
      <button type="submit" className="submit-btn">
        Log In
      </button>
    </form>
  );
};

export default LoginForm;