import React, { useState, useEffect } from 'react';
import Header from './components/header';
import MainMenu from './components/mainmenu';
import WorkOrderList from './components/workorderlist';
import './App.css';
import Customers from './components/customers';
import Reports from './components/reports';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [activeComponent, setActiveComponent] = useState('workOrderList');


  useEffect(() => {
    // Check for auth token in localStorage on component mount
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);


  return (
    <div className="App">
      <Header isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
      {isAuthenticated ? (
        <div className='content'>
          <MainMenu setActiveComponent={setActiveComponent} />
          {activeComponent === 'workOrderList' && <WorkOrderList />}
          {activeComponent === 'customers' && <Customers />}
          {activeComponent === 'reports' && <Reports />}
        </div>
      ) : (
        <div>Please log in to view content</div>
      )}
    </div>
  );
}

export default App;
