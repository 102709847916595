import './header.css';
import Horizontallogo from '../images/Horizontallogo.jpg'
import React, { useState  } from 'react';
import { useUserContext } from './UserContext.js';
import LoginSignupModal from './loginsignupmodal.js';



const Header = () => {
  const { user } = useUserContext();
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  

  return (
    <div className="header">
        <img className='imgHorizontallogo' src={Horizontallogo} alt='Horizontallogo' />
    
        <nav className="header-nav">
        {user ? (
          <>
            <button className="header-user-info" onClick={toggleModal}>
              {user.username}
            </button>
          </>
        ) : (
          <button className="header-login-btn" onClick={toggleModal}>
            Log In
          </button>
        )}
      </nav>
      <LoginSignupModal showModal={showModal} toggleModal={toggleModal} />
      
      
    </div>
  );
}

export default Header;
