import React from 'react';
import './mainmenu.css'; // Importing the CSS for styling

const MainMenu = ({ setActiveComponent }) => {
  return (
    <div className="mainmenu">
      <div className='menu'>
      <button className='menu-item' onClick={() => setActiveComponent('workOrderList')}>Work Orders</button>
      <button className='menu-item' onClick={() => setActiveComponent('customers')}>Customers</button>
      <button className='menu-item' onClick={() => setActiveComponent('reports')}>Reports</button>
      </div>
      <hr className='mainmenuline'/>
    </div>
  );
};

export default MainMenu;
 