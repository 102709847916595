import React, { useState, useEffect } from 'react';
import { fetchWorkOrders } from './api'; // Adjust the import path as necessary
import './workorderlist.css'
import WorkOrderModal from './workordermodal';
import NewWorkOrderModal from './newworkordermodal';


const CustomerWorkOrderList = ({customerName}) => {
  const [workOrders, setWorkOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);
  const [showNewWorkOrderModal, setShowNewWorkOrderModal] = useState(false); // State for showing the New Work Order Modal
  const [selectedStatus, setSelectedStatus] = useState('In Process');

 

  const toggleNewWorkOrderModal = () => {
    setShowNewWorkOrderModal(!showNewWorkOrderModal);
  };

  const handleWorkOrderCreated = (newWorkOrder) => {
    setWorkOrders([...workOrders, newWorkOrder]);
    setShowNewWorkOrderModal(false);
  };
  
 

  const toggleService = (service) => {
    setSelectedService(selectedService === service ? '' : service);
  };

  const toggleDate = (date) => {
    setSelectedDate(selectedDate === date ? '' : date);
  };

  const toggleStatus = (status) => {
    setSelectedStatus(selectedStatus === status ? '' : status);
  };
  
  

  useEffect(() => {
    // Set the search query to the customer's name
    
    setSearchQuery(customerName);
    const loadWorkOrders = async () => {
      setSearchQuery(customerName);
      const fetchedWorkOrders = await fetchWorkOrders(selectedService, selectedDate, searchQuery, selectedStatus);
      setWorkOrders(fetchedWorkOrders);
    };

    loadWorkOrders();
  }, [selectedService, selectedDate, customerName, selectedStatus]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  
  const handleWorkOrderClick = (workOrder) => {
    setSelectedWorkOrder(workOrder);
    setShowModal(true);
  };
  

  return (
    <div className='workorderlist'>
      

    <div className='workorderlistfilters'>
    <button className='newworkorderbutton'onClick={toggleNewWorkOrderModal}>New Work Order</button>
    <div className='statusfilter'>
  <label className='statuslabel'>Status:
  <select className='statusselect'value={selectedStatus} onChange={(e) => toggleStatus(e.target.value)}>
    <option value="In Process">In Process</option>
    <option value="Called">Called</option>
    <option value="Payed">Payed</option>
  </select>
  </label>
</div>


    

        {/* Service Buttons */}
    <label className='statuslabel'>Service:
    <div>
    <button className='workorderlistfiltersbutton' onClick={() => toggleService('Restoration')}>Restoration {selectedService === 'Restoration' && '✕'}</button>
    <button className='workorderlistfiltersbutton' onClick={() => toggleService('Straightening')}>Straightening {selectedService === 'Straightening' && '✕'}</button>
    <button className='workorderlistfiltersbutton' onClick={() => toggleService('Repair Crack')}>Repair Crack {selectedService === 'Repair Crack' && '✕'}</button>
    <button className='workorderlistfiltersbutton' onClick={() => toggleService('Powder Coating')}>Powder Coating {selectedService === 'Powder Coating' && '✕'}</button>
    <button className='workorderlistfiltersbutton' onClick={() => toggleService('Mount & Balance')}>Mount & Balance {selectedService === 'Mount & Balance' && '✕'}</button>
    </div>
    </label>
    {/* ... other service buttons ... */}

    {/* Date Buttons */}
    <label className='statuslabel'>Date:
    <div>
    <button className='workorderlistfiltersbutton' onClick={() => toggleDate('today')}>Today {selectedDate === 'today' && '✕'}</button>
    <button className='workorderlistfiltersbutton' onClick={() => toggleDate('tomorrow')}>Tomorrow {selectedDate === 'tomorrow' && '✕'}</button>
    </div>
    </label>

    {/* Search Bar */}
    <label className='statuslabel'>Search:
    <input
      className='searchbar'
      type="text"
      placeholder="⌕ Search Work Orders"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
    />
    </label>
    </div>
    <div className='workorderlistlabelsparent'>
    <div className='workorderlistlabels'>
      <h5 className='workorderlistlabelsheaders'>Customer Name</h5>
      <h5 className='workorderlistlabelsheaders'>Vehicle Make</h5>
      <h5 className='workorderlistlabelsheaders'>Service</h5>
      <h5 className='workorderlistlabelsheaders'>Date Promised</h5> 
    </div>
    <hr className='workorderlistlabelsheadersline'/>
    </div>
      {workOrders && workOrders.length > 0 ? (
        workOrders.map((order) => (
          // Render your work order here
          <div className='workorderlistrow' key={order.id} onClick={() => handleWorkOrderClick(order)}>
            <div className='workorderlistrowdataname'>{order.customer_name}</div>
            
            <div className='workorderlistrowdatavehicle'>{order.vehicle}</div>
            
            <div className='workorderlistrowdataservice'>{order.service_requested}</div>
            
            <div className='workorderlistrowdatadate'>{order.date_promised}</div>
            </div> // Example, adjust according to your data structure
        ))
      ) : (
        <p>No work orders found</p>
      )}
      <NewWorkOrderModal
        showModal={showNewWorkOrderModal}
        toggleModal={toggleNewWorkOrderModal}
        onWorkOrderCreated={handleWorkOrderCreated}
      />
      <WorkOrderModal showModal={showModal} toggleModal={toggleModal} workOrder={selectedWorkOrder} />
      
    </div>
  );
};

export default CustomerWorkOrderList;